/*
    =========================
    Global Survey Styles
    =========================
*/

.survey {
    // hides the tabbed menu in the designer
    .svc-tabbed-menu .svc-tabbed-menu-item-container:first-child {
        display: none;
    }
}


/*
    =========================
    Survey Designer Styles
    =========================
*/
.survey.survey--designer {
    //--primary: #346189;
    //--foreground: #346189;
    //--secondary: #193962;
    //--primary-light: rgba(26, 183, 250, 0.1);
    //-primary-foreground: #ffffff;
    //--background: #555555;
}


/*
    ===============================
    Common Styles for Preview & Taker
    ===============================
*/
.survey.survey--preview, .survey.survey--taker {

    // description color
    .sd-description {
        color: $color-medium-blue;
    }

    // normalize question number styles to match question titles
    .sd-element__title .sd-element__num {
        font-size: 1rem;
        padding-top: 4px;
        color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
    }
    // update position of question number
    .sd-element--complex.sd-element--with-frame > .sd-element__header--location-top:after {
        bottom: -1rem;
    }

    // bold the questions
    .sd-element__title .sv-string-viewer {
        font-weight: 700;
    }

    // font-sizes
    .sd-table__cell {
        font-size: 14px;
    }
    .sd-table__cell--header span {
        font-size: 14px;
    }
    .sd-item__control-label {
        font-size: 14px;
    }

    // paddings
    .sd-element--complex.sd-element--with-frame > .sd-element__header--location-top {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .sd-element--with-frame {
        padding-bottom: 20px;
    }
    .sd-table__cell--header {
        padding-bottom: 3px;
    }

    // disable the focus ring
    .sd-item__control[type="radio"]:focus + .sd-item__decorator {
        background: var(--sjs-primary-backcolor, var(--primary, #19b394));
        box-shadow: none;
    }
    .sd-item__control[type="radio"]:focus + .sd-item__decorator:after {
        content: " ";
        display: block;
        width: var(--sjs-base-unit, var(--base-unit, 8px));
        height: var(--sjs-base-unit, var(--base-unit, 8px));
        border-radius: 50%;
        background-color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    }

    // adjust padding for matrixdropdown grid
    .sd-table.sd-matrixdropdown,
    .sd-table.sd-matrix__table {
        td {
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: middle;
        }
        td:first-child {
            padding-top: 0;
            padding-bottom: 0;
        }
        .sd-item.sd-radio {
            vertical-align: middle;
        }
    }


    // Mobile Styles

    // do not show cell column titles in mobile for each question
    .sd-root-modern.sd-root-modern--mobile .sd-table__cell::before {
        display: none !important;
    }

    // show each question in a single row in mobile (multi-select matrix)
    .sd-root-modern.sd-root-modern--mobile .sd-item.sd-radio.sd-selectbase__item--inline {
        display: block !important;
        width: 100% !important;
    }

    // adjust padding for matrixdropdown grid
    .sd-root-modern.sd-root-modern--mobile {
        .sd-table.sd-matrixdropdown {
            .sd-question--answered {
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
            }
            td:first-child {
                padding-top: 1rem;
            }
        }
    }

    @media (max-width: 600px) {
        .sd-header__text .sd-title {
            font-size: 1.5rem;
        }
    }
}

.survey.survey--taker {
    .sd-root-modern .sd-container-modern__title {
        background-color: transparent;
    }
    .sd-title.sd-container-modern__title {
        box-shadow: none;
        padding: 0;
        overflow: hidden;
    }
    .sd-body.sd-body--responsive {
        padding: 0;
    }
    .sd-page.sd-body__page {
        padding-top: 2.5rem;
    }
}