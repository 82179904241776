.confirmation-modal {
	padding: 34px;
	box-shadow: 0px 0px 10px #0000001A;
	border-radius: 10px;

	.modal-title {
		color: $color-dark-blue !important;
		font-size: 24px;
		font-weight: bold;
	}

	.modal-warning {
		margin: 22px 0;
		color: #333333;
		font-size: 16px;
		letter-spacing: 0px;
	}
}