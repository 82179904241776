.print-only {
  display: none !important;
}

@media print {
  .no-print, #hubspot-messages-iframe-container {
    display: none !important;
    visibility: hidden !important;
  }

  .print-only {
    display: block !important;
  }

  .avoid-page-break {
    page-break-inside: avoid;
  }

  .ensure-page-break-before {
    page-break-before: always;
  }

  .btn {
    display: none;
  }

  .att-chart-circle {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .care-heatmap th,
  .care-heatmap td {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
