.footer-spacer {
  height: 87px;
  width: 100vw;
  margin-top: 100px;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 87px;
  width: 100vw;
  background: #707070 0% 0% no-repeat padding-box;
  color: white;

  &.open {
    padding-left: 400px;
  }

  a {
    text-decoration: none;
    color: white;
  }

  .left-side {
    margin-left: 80px;
    margin-top: 30px;

    .logo {
      margin-right: 26px;
    }
  }

  .right-side {
    margin-right: 80px;
    margin-top: 30px;
  }

  .link-spacer {
    padding: 0 20px;
    color: $primary;
  }
}
