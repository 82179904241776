$headline-color: #0C2C57;

.read-only {
	.MuiButtonBase-root-MuiRadio-root.Mui-checked, .MuiButtonBase-root.MuiRadio-root  {
	  color: #346189 !important;
    }
	label, .MuiInputBase-root, .Mui-disabled {
	  color: inherit !important;
	  -webkit-text-fill-color: rgb(25, 57, 98) !important;

	  .MuiSelect-nativeInput + .MuiSvgIcon-root, .MuiAutocomplete-endAdornment {
		display: none;
	  }
	}
	.headline-color {
		color: $headline-color !important;
	}
}

.notes-title.headline-color {
	color: $headline-color !important;
}

.adm-selector {
	$base-font-color: #333333;
	$section-bg-shade: #F5F8FA;

	color: $base-font-color;

	.headline-color {
		color: $headline-color !important;
	}

	.base-color {
		color: $base-font-color !important;
	}

	.fa-circle-info {
		color: #c0cbd7;
	}
	.aspect {
		.content {
			margin-top: 30px;
			margin-bottom: 10px;
		}
	}
	.Mui-expanded.MuiAccordion-root {
		border: 0 !important;
	}
	.summary-indicator {
		.summary-indicator-description {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			visibility: hidden;
			transition: opacity 0.5s ease, max-height 0.5s ease, visibility 0.5s ease;
			transition-delay: 0.1s;
		}
		.summary-indicator-description.show {
			max-height: 100px;
			opacity: 1;
			visibility: visible;
		}
		.summary-indicator-check-all {
			opacity: 0;
			visibility: hidden;
			white-space: nowrap !important;
			display: inline-flex !important;
			text-decoration: none;
			font-size: 13px;

			&:hover {
				text-decoration: underline;
			}
		}
		.summary-indicator-check-all.show {
			opacity: 1;
			visibility: visible;
		}

		.MuiAccordionSummary-root {
			border-bottom: none !important;
			min-height: auto;
		}
		.MuiAccordionDetails-root {
			background: $section-bg-shade;
		}
		.MuiAccordionSummary-content {
			margin: 0;
		}
	}
	.indicator {
		font-size: 16px;
		border-bottom: 1px solid #E3EBF0;

		&:last-child {
			border-bottom: none;
		}
	}
}

.care-reporting {
	.filter-by-header {
		background-color: #F5F8FA;
		padding: 1rem;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
		min-width: 180px;
	}
	.MuiAccordionDetails-root {
		padding-top: 0 !important;
	}
}

.add-goals-form {
	.MuiFormHelperText-root {
		margin: 0;
		background: #F5F8FA;
		padding-top: 3px;
	}
}