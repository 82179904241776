.initiative-scheduling {
  .btn-outline-primary {
    background-color: $color-light-gray;
    color: $color-dark-blue;
    border-color: white;
  }

  .btn-check:checked + .btn-outline-primary {
    background-color: $color-dark-blue;
    color: white;
    border-color: white;
  }
}

.initiative-scheduling-form.not-modifiable {
  background-image: none;
}