.topnav {
  height: 60px;
  background: $color-dark-blue;
  color: white;

  // @deprecated?
  .breadcrumb {
    display: inline-block;
    font-size: 22px;
    font-weight: 700;
    color: white;
    margin-bottom: 0;
    font: normal normal normal 16px/22px $font-family-base;
  }

  a, .link {
    &.active {
      color: $white;
    }

    &.disabled {
      cursor: default;
      color: #526A89;
    }

    display: inline-block;
    position: relative;
    cursor: pointer;
    text-decoration: none !important;
    color: $primary;
    font-size: 20px;
    margin: 5px;
    font: normal normal medium 18px/52px $font-family-base;
    letter-spacing: 0px;

    img {
      width: 21px;
    }
  }

  // @deprecated?
  .links {
    position: relative;

    .popover {
      display: none;
      inset: 0 auto auto -15px !important;
      width: 300px;
      padding: 20px;
      font-size: 16px;

      // @deprecated?
      a {
        text-decoration: none;
        color: black;
      }

      // @deprecated?
      .name {
        font-size: 18px;
        color: $color-dark-blue;
        font-weight: bold;
      }

      // @deprecated?
      .email {
        font-size: 14px;
      }

      // @deprecated?
      .section {
        padding: 15px 0;
      }

      // @deprecated?
      .section:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
    }

    .popover[data-show] {
      display: block;
    }
  }
}

// popper arrow
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
  border-left: 1px solid gray;
  border-top: 1px solid gray;
}

.popover[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

.popover[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
  right: 45px;
}

.popover[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

.popover[data-popper-placement^="right"] > #arrow {
  left: -4px;
}
