.homepage {
  .header {
    font-size: 40px;
    color: $color-dark-blue;
    margin-top: 28px;
    margin-bottom: 38px;
  }
}

.information {
  iframe {
    width: 100%;
    height: 100vh;
  }
}