.cycle-table-row.editing {
  .MuiTableCell-root {
	border-bottom: 0;
  }
}

.blue-table-header {
  color: #0C2C57;
  font-size: 15px;
}

.leads-reports {
    &.inactive {
      box-shadow: none;
      background: #F4F4F4;
      .lead-name {
        color: #969696;
      }
    }

    &.complete {
      border: 4px solid #7BB9B8;
    }
}

.progress-report-list-item {
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .MuiAccordionSummary-content {
    margin-top: 0;
  }

  .MuiAccordionSummary-root.expanded {
    td {
      border-bottom: 0px;
    }
  }

  .pm-quant-heading {
    .MuiAccordionSummary-content {
      margin-bottom: 0;
    }
  }
}

.pm-back-to-overview-link {
  text-decoration: none;
  color: $color-dark-blue;
}

.progress-report-container {
  margin-left: 50px;

  //check this
  padding: 25px;
  border: 1px solid #C0CFDB;
}

.reviewer-title {
  color: #0C2C57;
}