@font-face {
  font-family: BrandonText;
  src: url("../fonts/HVD Fonts - BrandonText-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: BrandonText;
  src: url("../fonts/HVD Fonts - BrandonText-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: BrandonText;
  src: url("../fonts/HVD Fonts - BrandonText-Bold.otf") format("opentype");
  font-weight: bold;
}

$primary: #67bbb9 !default;
$font-size-base: 1rem !default;
$font-family-base: "BrandonText";

// Attuned Colors
$color-dark-blue: #193962;
$color-light-gray: #e3ebf0;
$color-medium-gray: #707070;
$color-dark-gray: #5d5d5d;
$color-medium-blue: #346189;
$color-dark-dark-blue: #0C2C57;


.text-color-black {
  color: black;

  &:hover {
    color: black;
  }
}

.text-color-medium-gray {
  color: $color-medium-gray;
}

// in absence of root variables defining our theme, temp declaring the current primary text color.
// note: there's a conflicting variable in the bootstrap, with the same name.
.text-primary {
  color: $primary;
}

.link-text {
  color: $color-medium-blue;
}

.link-default {
  color: $color-medium-blue;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $color-dark-blue;
    text-decoration: underline;
  }
}

.link-dark-no-underline {
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $color-medium-blue;
    text-decoration: underline;
  }
}

.hover-underlined {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

// add a smaller flavor of Boostrap font-size classes (fs-1 - fs-6, where f6 = 1rem)
.fs-7 {
    font-size: 0.75rem;
}
.fs-8 {
    font-size: 0.7rem;
}

.cursor-pointer {
  cursor: pointer;
}
