.strategic-priority {
  p {
    margin-bottom: 0;
  }
}

.accordion-item {
  border: 0;
}

.accordion-header, .MuiAccordionSummary-root {
  &.strategic-priority {
    .accordion-button {
      background: #67BBB840 !important;
      color: #333333;
      border: 0;
      border-left: 6px solid #62BBBA;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &.initiative {
    .accordion-button {
      background: #C0CFDB33;
      color: #333333;
      border-left: 6px solid #8BA8BE;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
}

.accordion-item {
    &.initiative {
      margin-bottom: 5px;
    }
}

.strategic-priority-list-item {
  background: #67BBB840 !important; // this 8-digit hex color adds opacity, it is equivalent to #D9EEED
  color: #333333 !important;
  border: 0 !important;
  border-left: 6px solid #62BBBA !important;
  border-radius: 5px 5px 0 0;
}

.initiative-list-item {
  background: #C0CFDB33;
  color: #333333;
  border-left: 6px solid #8BA8BE;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#initiatives-index-react {
  &.border-left {
    border-left: 6px solid #62BBBA59;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .Mui-expanded.MuiAccordion-root {
    border: 0 !important;
  }
}

.strategic_priority-border-left, .priority-border-left {
  border-left: 6px solid #62BBBA59;
}

.initiative-border-left {
  border-left: 6px solid #C0CFDB80;
}

.plan-element-type {
  font-size: 14px;
  letter-spacing: 0;
  color: #333333;
  font-weight: bold;
  margin-right: 15px;
  line-height: 1;

  .numbering {
    font-size: 22px;
    font-weight: 400;
  }
}