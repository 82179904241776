#my-work-react {
  th {
    color: $color-dark-dark-blue;
    padding-top: 0;
  }

  .MuiTableRow-root:last-child td {
    border-bottom: 0;
  }

  .ais-InfiniteHits-loadMore {
    background: $color-medium-blue !important;
    padding: 20px;
    color: white;
    border: 0;

    &:disabled {
      opacity: 0.7;
    }
  }

  .ais-Hits-item, .ais-InfiniteHits-item {
    box-shadow: none;
    border-bottom: 1px solid $color-light-gray;
    padding-top: 4px;
    padding-bottom: 4px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .ais-SearchBox-input {
    box-shadow: none;
    border: 1px solid $color-light-gray;
  }

  .MuiBadge-root .MuiBadge-badge {
    top: -5px;
  }
}

* {
   .MuiBadge-root .MuiBadge-badge {
    padding: 0 4px !important;
    font-size: 10px;
    min-width: fit-content;
    width: 15px;
    height: 15px;
  }
}


