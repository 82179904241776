@import "../stylesheets/themes/settings";
@import "~bootstrap/scss/bootstrap";
@import "../stylesheets/topnav";
@import "../stylesheets/footer";
@import "../stylesheets/project-nav";
@import "../stylesheets/homepage";
@import "../stylesheets/org-table";
@import "../stylesheets/print-out";
@import "../stylesheets/deliverables-table";
@import "../stylesheets/progress-monitoring";
@import "../stylesheets/initiative-scheduling";
@import "../stylesheets/components/strategic-priority";
@import "../stylesheets/components/measures-table";
@import "../stylesheets/components/drag-drop-file-upload";
@import "../stylesheets/components/rich-text-editor";
@import "../stylesheets/views/initiatives-index-react";
@import "../stylesheets/views/my-work-react.scss";
@import "../stylesheets/views/overview-react.scss";
@import "../stylesheets/views/progress-report-react.scss";
@import "instantsearch.css/themes/satellite.css";
@import "../stylesheets/confirm-modal.scss";
@import "../stylesheets/care.scss";
@import "../stylesheets/vendor/surveyjs";
@import "survey-core/defaultV2.min.css";
@import "survey-creator-core/survey-creator-core.min.css";

/**
NOTE: The styles below closely resemble the PATCH files we use for rapid hotfixes.
Since they are all global and unscoped, be mindful that future refactoring could require a considerable amount of testing.
Let's think twice before adding to this file and only do so if it's strictly necessary.

Example: This global style adds a margin bottom to all <p> tags. Instead, use utility classes, something like "p.mb-1"
p {
  margin-bottom: 5px;
}
 */

.btn-light {
  background-color: #e3ebf0 !important;
}

.blue-header {
  color: $color-dark-blue;
}

.btn-att-gray {
  min-width: 200px;
  color: $color-dark-blue;
  background-color: $color-light-gray;
  padding: 10px;
  height: 50px;
  border-radius: 7px;
}

.btn-att-gray-small {
  min-width: 100px;
  color: $color-dark-blue;
  background-color: $color-light-gray;
  padding: 4px;
  height: 30px;
  border-radius: 7px;
  font-size: 12px;
  cursor: pointer;
}

.btn-att-gray-medium {
  min-width: 100px;
  color: $color-dark-blue;
  background-color: $color-light-gray;
  padding: 4px;
  height: 40px;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
}

.btn-att-blue {
  min-width: 200px;
  color: white;
  background-color: $color-medium-blue;
  padding: 10px;
  height: 50px;
  border-radius: 7px;
}

.btn-att-blue-small {
  min-width: 100px;
  color: white;
  background-color: $color-medium-blue;
  padding: 4px;
  height: 30px;
  border-radius: 7px;
  font-size: 12px;
}

.btn-att-blue-medium {
  min-width: 100px;
  color: white;
  background-color: $color-medium-blue;
  padding: 4px;
  height: 40px;
  border-radius: 7px;
  font-size: 16px;
}

.btn-disabled {
  background-color: #eeeeee;
  color: #888888;
}

.btn-att-blue:hover,
.btn-att-blue-small:hover,
.btn-att-blue-medium:hover {
  color: white;
}

.accordion-button {
  padding: 5px 15px !important;
}

.table > :not(:first-child) {
  border-top: none;
}

.att-dark-blue {
  color: $color-dark-blue;
}

body {
  position: relative;
  min-height: 100vh;
}

td {
  vertical-align: middle;
}

.hide {
  display: none;
}

.MuiTypography-root { // stylelint-disable-line selector-class-pattern
  font-family: $font-family-base !important;
}

p {
  margin-bottom: 5px;
}

.pre-line {
  white-space: pre-line;
}

// patch for bootstrap v5.1.3
.form-select-white-caret {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.form-select-gray-caret {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23d3d3d3' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

// Quick patch to support a "white" variant of the accordion
.accordion-item.white {
  box-shadow: 0 0 10px #0000001A;
  border-radius: 10px;

  .accordion-button {
    color: $color-dark-blue !important;
    background-color: #fff !important;
    &:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .accordion-button:not(.collapsed) {
    color: $color-dark-blue !important;
    background-color: #fff !important;
    box-shadow: none !important;
  }
}

.MuiButtonBase-root.accordion-button {
  background-color: #67bbb840 !important;
}

.no-legend {
  legend {
    display: none !important;
  }
}

.unset-legend-width {
  legend {
    width: unset !important;
  }
}

.body-content-wrapper {
  max-width:1920px;
  margin: 0 auto;
}