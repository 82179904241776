.progress-report-react, .progress-reports {
	.MuiPaper-root {
		border: 0px !important;
	}

	.report-accordion {
		box-shadow: none !important;
	}

	.Mui-expanded.MuiAccordion-root {
		border: 0px !important;
	}

	h1, h2, h3, .prompt {
		color: $color-dark-dark-blue;
	}

	.prompt {
		margin-top: 15px;
	}

	.MuiRadio-root.Mui-disabled, .MuiFormControlLabel-label.Mui-disabled {
		color: $color-dark-dark-blue !important;
	}

	.MuiFormControl-root.no-label {
		legend {
			display: none;
		}
	}

	.deliverables-report .PrivateSwitchBase-input {
		cursor: initial;
	}

	.filename {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
}

.lead-name {
	color: $color-dark-dark-blue;
	font-size: 22px;
	font-weight: bold;
}


.report-due-date.overdue {
	color: #FF004E;
}