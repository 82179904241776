.progress-bar-label {
	font-size: 11px;
	font-family: $font-family-base;
}

#measures-tab {
  .accordion-button {
	background-color: #67BBB940;
	color: #5da8a7;
  }
}

#plan-overview-react {
	.accordion-button h5 {
	  font-weight: bold;
	}

	.accordion-button:not(.collapsed)::after {
	  background-image: none;
	}

	.MuiAccordionSummary-content {
		margin: 0 !important;
	}

	h6 {
		font-weight: 600;
		text-align: left;
		font: normal normal medium 16px/31px Brandon Text;
		letter-spacing: 0px;
		color: $color-dark-dark-blue;
		opacity: 1;
	}

	.measure-name {
		font-size: 15px;
	    line-height: 1.1;
	}
	.measure-list {
		border-left: 6px solid #C0CFDB80;
		padding-left: 10px;
	}

	.initiative {
		border: 1px solid var(---c0cfdb);
		background: #C0CFDB40;
		border-left: 6px solid #8BA8BE;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		opacity: 1;
		color: $color-dark-dark-blue;
		padding: 10px 15px;
		width: 100%;
		line-height: 1;
		margin-top: 20px;
	}

	.measure {
		border: 1px solid #C0CFDB;
		border-radius: 5px;
		opacity: 1;
		padding: 1.2rem 1.1rem;
		width: 100%;
	    cursor: pointer;
	    color: black;
	    line-height: 1;
	    a {
  	      text-decoration: none;
  	      color: black;
  	      display: flex;
	      flex-direction: column;
	      justify-content: space-between;
	    }
	}

	.line {
		height: 2px;
		background: #00000029;
	}

	.mission-measures {
	  .measure {
		height: 100%;
	  }
	}

	.MuiAccordion-root {
		border: 0 !important;
	}
}