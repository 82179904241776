#initiatives-index-react, #strategic_priorities_new_react {
  .years-headers {
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-right: 1px solid lightgrey;
  }

  .years-headers:first-child {
    border-left: 1px solid lightgrey;
  }

  .year-label {
    font-weight: bold;
    line-height: 45px;
    border-right: 1px solid lightgrey;
  }

  .MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: black;
  }

  .MuiTextField-root {
    background-color: white;
  }
}
