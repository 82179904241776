.editor-container {
  // Reset Font Family
  .ProseMirror {
    font-family: inherit !important;
    & h1, & h2, & h3, & h4, & h5, & h6 {
      font-family: inherit !important;
    }
  }

  // Reset Headings
  h1.rte-heading {
    font-size: 2em !important;
    font-weight: bold;
  }
  h2.rte-heading {
    font-size: 1.5em !important;
    font-weight: bold;
  }
  h3.rte-heading {
    font-size: 1.17em !important;
    font-weight: bold;
  }

  // Undo _reboot.css reset
  hr.MuiDivider-vertical {
    height: 18px !important;
  }

  .MuiTiptap-MenuBar-sticky {
    position: sticky;
    top: 60px;
  }

  // Rubric tables must have equal column widths
  // ----------------------------------------------------- LDOE Rubric Tables
  &.-readonly.is-rubric {
    .ProseMirror table {
      width: 100% !important;
      table-layout: fixed !important;
    }
    .ProseMirror table th {
      font-weight: 400 !important;
    }
    .ProseMirror table th, .ProseMirror table td {
      width: 1% !important;
    }
  }
  // ----------------------------------------------------- LDOE Rubric Tables
}