.project-nav {
  margin-bottom: 15px;

  h1 {
    font-size: 40px;
    color: $color-dark-blue;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  a {
    display: inline-block;
    text-decoration: none;
    font-size: 18px;
    color: $color-dark-gray;
    margin-right: 30px;
    line-height: 52px;

    // white-space: nowrap;
  }

  a.active {
    border-bottom: 5px solid $primary; // TODO: Why is $primary not the Attuned primary but Bootstrap primary!?
    color: $color-dark-blue;
    font-weight: 700;
  }

  .divider {
    position: absolute;
    left: 0;
    width: 100vw;
    border-bottom: 1px solid lightgrey;
  }
}

.project-nav.surveys {
  margin-bottom: 1px;
  .form-check-input {
    width: 2.7em;
    height: 1.5em;
  }
}