.org-table {
  .title {
    color: $color-dark-blue;
    font-size: 16px;
  }

  .has-children {
    font-weight: bold;
    position: relative;
  }

  .has-children::before {
    position: absolute;
    top: 7px;
    left: 3px;
    font-size: 22px;
    content: ">";
    transform: rotate(90deg);
  }

  .has-children.open::before {
    left: -3px;
    transform: rotate(270deg);
  }
}
