.deliverables-table {
  .title {
    color: $color-dark-blue;
    font-size: 16px;
    margin-bottom: 50px;
  }

  table,
  th,
  td {
    border: 1px solid $color-light-gray;
  }

  th {
    font-weight: bold;
  }

  td.date {
    min-width: 150px;
  }
}

.deliverable-form, .strategic-priority {
  h5 {
    color: $color-dark-dark-blue;
  }

  .color-medium-gray {
    color: $color-medium-gray;
  }
}

.btn-link-dark-blue {
  color: $color-medium-blue;
}

tr.deliverable {
  &:last-of-type {
    border-bottom: 1px solid lightgray;
  }
}

.error-text {
  color: red;
}