.MuiTable-root.measures-table {
  border: 1px solid $color-light-gray;
  padding-top: 20px;
  width: 98%;

  .MuiTableCell-root {
    border-right: 1px solid $color-light-gray;

    &.vertical-align {
      vertical-align: baseline;
    }

    .attribute-entry {
      min-height: 20px;
    }
  }

  th {
    color: $color-dark-dark-blue !important;
  }
}


.measures-form .MuiTable-root {
  th {
    color: $color-dark-dark-blue !important;
    vertical-align: bottom;
    padding-bottom: 0;
    border-bottom: 0;
  }
}